import { render, staticRenderFns } from "./SiteNavigation.vue?vue&type=template&id=2b882eb0&scoped=true"
import script from "./SiteNavigation.vue?vue&type=script&lang=js"
export * from "./SiteNavigation.vue?vue&type=script&lang=js"
import style0 from "./SiteNavigation.vue?vue&type=style&index=0&id=2b882eb0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b882eb0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LogoLightBtn: require('/vercel/path0/components/ui/LogoLightBtn.vue').default,MainNav: require('/vercel/path0/components/navigation/MainNav.vue').default,SearchBar: require('/vercel/path0/components/navigation/SearchBar.vue').default,ActionButtons: require('/vercel/path0/components/navigation/ActionButtons.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default,Modal: require('/vercel/path0/components/ui/Modal.vue').default})
